import { observable, action, computed } from 'mobx';
import { CallRateCategory } from '~/types/callRates.types';
import * as callRateCategoryApi from '~/api/cell/callRateCategoryApi';

import ListStorePrototype from '../prototypes/ListStore.prototype';
import deepCopy from '~/common/deepCopy';
import { DropdownType } from '../prototypes/ListStore.prototype';

import authStore from '../authStore';

const ALL_RATES_CATEGORIES_KEY = 'ALL_RATES_CATEGORIES_KEY';

class CallRateCategoryStore extends ListStorePrototype<CallRateCategory> {
    constructor() {
        super('category_id', 'settings', callRateCategoryApi);
        authStore.registerInitFunc(this.fetchAllCategories.bind(this));

        const memoryListJSON = window.localStorage.getItem(ALL_RATES_CATEGORIES_KEY);
        if (memoryListJSON) {
            this.loadingAllCategories = false;
            this.allCategories = JSON.parse(memoryListJSON);
        }
    }

    @observable
    loadingAllCategories = true;

    allCategories: CallRateCategory[] = [];

    @computed
    get allCategoriesDropdown(): DropdownType[] {
        return this.allCategories.map(({ category_id, title }) => ({
            text: title,
            value: category_id,
            key: category_id
        }));
    }

    @action
    async fetchAllCategories() {
        this.allCategories = await callRateCategoryApi.fetchAllCallRateCategories();
        this.loadingAllCategories = false;
        window.localStorage.setItem(ALL_RATES_CATEGORIES_KEY, JSON.stringify(this.allCategories));
    }

    @action
    async fetchItem(id: number, base?: string | null) {
        await super.fetchItem(id);
        const item = this.getItem(id).item;
        if (item) {
            this.setEditingItem(id, deepCopy(item));
        }
    }

    @action
    async createItem(): Promise<number> {
        const category_id = await super.createItem();

        this.loadingAllCategories = true;
        await this.fetchAllCategories();

        return category_id;
    }

    findById(category_id: number): CallRateCategory {
        const callRateCategory = this.allCategories.find(item => item.category_id === category_id);

        if (!callRateCategory) {
            throw new Error(`Call Rate Category didn't find: ${category_id}`);
        }

        return callRateCategory;
    }
}

export default new CallRateCategoryStore();
