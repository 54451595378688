import fetchApi from '~/common/fetchApi';
import { CallRateCategory } from '~/types/callRates.types';
import { objectToGraphql } from '~/common/graphql';

const CategoryGQLFields = `
    category_id
    title

    createTime
    updateTime
    enable
`;

export const createItem = async (item: CallRateCategory): Promise<number> => {
    const graphql = `mutation { 
          createCallRateCategory(item: ${objectToGraphql(item)})
        }`;

    const data = await fetchApi.postGQ<{ createCallRateCategory: number }>(graphql);
    return data.createCallRateCategory;
};

export const saveItem = async (category_id: number, diff: CallRateCategory): Promise<number> => {
    const graphql = `mutation { 
        updateCallRateCategory(category_id: ${category_id}, diff: ${objectToGraphql(diff)})
    }`;

    const data = await fetchApi.postGQ<{ updateCallRateCategory: number }>(graphql);
    return data.updateCallRateCategory;
};

export const fetchItem = async (category_id: number): Promise<CallRateCategory> => {
    const graphql = `{
            fetchCallRateCategory(category_id: ${category_id}) {
                ${CategoryGQLFields}
            } 
          }`;

    const data = await fetchApi.getGQ<{ fetchCallRateCategory: CallRateCategory }>(graphql);
    return data.fetchCallRateCategory;
};

export const fetchList = async (): Promise<{ list: CallRateCategory[]; count: number }> => {
    const graphql = `{
            fetchCallRatesCategories {
                list {
                    ${CategoryGQLFields}
                }
                count
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchCallRatesCategories: { list: CallRateCategory[]; count: number } }>(graphql);
    return data.fetchCallRatesCategories;
};

export const fetchAllCallRateCategories = async (): Promise<CallRateCategory[]> => {
    const graphql = `{
        fetchAllCallRateCategories {
            ${CategoryGQLFields}
        }
      }`;

    const data = await fetchApi.getGQ<{ fetchAllCallRateCategories: CallRateCategory[] }>(graphql);
    return data.fetchAllCallRateCategories;
};
