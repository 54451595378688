import React, { Fragment } from 'react';
import { TableCell, TableRow, TableBody } from '~ui/Table';

import { CallRate } from '~/types/callRates.types';

import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { secondsToMS } from '~/common/time';
import Time from '~/components/Base/Time';
import UserLink from '~/components/Lists/Users/UserLink';
import RateCellCall from '~/components/Lists/CellCalls/RateCellCall';
import ContactLink from '~/components/Lists/Contacts/ContactLink';
import callRateCategoryStore from '~/stores/cell/callRateCategoryStore';

type CallRatesTableBodyProps = {
    callRatesList: CallRate[];
};

const CallRatesTableBody = (props: CallRatesTableBodyProps) => {
    const { callRatesList } = props;

    return (
        <TableBody>
            {callRatesList.map(
                ({
                    call_rate_id,
                    call_id,
                    rate,
                    comment,
                    user,
                    major_user_id,
                    improvementSuggestions,
                    positiveFeedback,
                    call,
                    caller,
                    contact,
                    category_id,
                    updateTime,
                    createTime
                }) => {
                    return (
                        <Fragment key={call_rate_id}>
                            <TableRow>
                                <TableCell>
                                    <Time time={createTime} />
                                </TableCell>
                                <TableCell>
                                    <UserLink icon user={user} />
                                </TableCell>
                                <TableCell>
                                    <Box display="flex" alignItems="center" flexWrap="wrap" style={{ gap: '0.5rem' }}>
                                        {typeof rate === 'number' && (
                                            <Box display="flex" alignItems="center" style={{ gap: '0.25rem' }}>
                                                {rate}
                                                <Rating size="small" max={1} precision={0.1} value={rate / 5} readOnly />
                                            </Box>
                                        )}
                                        {(rate === null || rate === undefined) && <div>—</div>}
                                        {category_id && (
                                            <Typography variant="caption" color="textSecondary">
                                                {callRateCategoryStore.findById(category_id)?.title}
                                            </Typography>
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <UserLink icon user={caller} />
                                </TableCell>
                                <TableCell>{contact ? <ContactLink contact={contact} /> : '—'}</TableCell>
                                <TableCell>
                                    {/*<DownloadLink {...call} />*/}
                                    {/*<span>{secondsToMS(Math.round((call.releaseTime - call.answerTime) / 1000))}</span>*/}
                                    <Fragment>
                                        <RateCellCall callMetadata={call} major_user_id={major_user_id} />
                                        <span title={`id: ${call_id}`}>
                                            {secondsToMS(Math.round((call.releaseTime - call.answerTime) / 1000))}
                                        </span>
                                    </Fragment>
                                </TableCell>
                            </TableRow>
                            {(comment || positiveFeedback || improvementSuggestions) && (
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <Box display="flex" style={{ gap: '1rem' }} alignItems="center" flexWrap="wrap">
                                            {comment && (
                                                <>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Комментарий:
                                                    </Typography>
                                                    <Typography variant="body2">{comment}</Typography>
                                                </>
                                            )}
                                            {positiveFeedback && (
                                                <>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Хорошо:
                                                    </Typography>
                                                    <Typography variant="body2">{positiveFeedback}</Typography>
                                                </>
                                            )}
                                            {improvementSuggestions && (
                                                <>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Можно улучшить:
                                                    </Typography>
                                                    <Typography variant="body2">{improvementSuggestions}</Typography>
                                                </>
                                            )}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )}
                        </Fragment>
                    );
                }
            )}
        </TableBody>
    );
};

export default CallRatesTableBody;
